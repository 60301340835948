<template>
  <div id="divContactUsWrapper" class="co-wrapper"><div v-html="content"></div></div>
</template>
<script>
import StaticContent from '@/services/Api/staticContents';

import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'ContactUs',
  data() {
    return {
      content: '',
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  created() {
    StaticContent.getStaticContent('BizeUlasin').then(res => {
      if (res.data.Data) {
        this.content = res.data.Data.content;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.co-wrapper {
  text-align: left;
  padding: palette-space-level(10);
  white-space: pre-line;
}
</style>
